import React, { useEffect } from "react";
import { onBlankLayout } from "store/slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { loadProfileDetails } from "services/appServices/editProfileService";
import { setProfileDetails } from "store/slices/profileSlice";
import { getSettings } from "store/slices/settingSlice";
import { loadSettingDetails } from "services/appServices/siteSettingService";

const AppRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  ...props
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));
  }, [blankLayout]);

  const profile = useSelector((state) => state?.profile?.pofileData);
  useEffect(() => {
    loadProfileDetails().then((res) => {
      dispatch(setProfileDetails(res?.data?.data));
      // console.log(res?.data?.data, "ssssssss");
    });
  }, []);

  const settings = useSelector((state) => state?.settings?.data);
  useEffect(() => {
    loadSettingDetails().then((res) => {
      dispatch(getSettings(res?.data));
    });
  }, []);

  return <Component {...props} />;
};

export default AppRoute;
